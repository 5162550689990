import React from "react"
import Logo from "../components/image"
import { Link } from "gatsby"
import SEO from "../components/seo"

function PrivacyPolicyPage () {

    return (
        <>
        <SEO title="Privacy Notice" />
        <section className="grid grid-cols-12 bg-gradient-to-r from-white via-ms_turquoise to-ms_blue h-full">
             <div className="col-start-2 col-span-6 lg:col-start-4 mt-10 lg:col-span-3" style={{ maxWidth: `250px`, borderRadius: `5em` }}>
                <Link to="/">
                    <Logo />
                </Link>
            </div>
            <div className="col-start-2 col-span-10 lg:col-start-4 lg:col-span-6 mt-16 mb-20 text-sm">
            <h1>Privacy Notice</h1>
            
            <p><br/>MySnapshot Limited, trading as MySnapshot, understands that your privacy is important to you and that you care about how your personal data is used.<br/><br/></p>
            <p>This notice explains MySnapshot’s approach to protecting your data. This includes how all data is shared by visitors with MySnapshot, either directly through the use of our services or via email, and provides an explanation to what happens to any personal data that is shared with MySnapshot through our services, website www.mysnapshot.co (“Our Site”), or email communications.<br/><br/></p>
            <p>We respect your privacy, and we uphold in accordance with the GDPR:<br/><br/></p>
            <p>(a) To collect and use personal data in ways that are described in this notice, and in a way that is consistent with our obligations and your rights under the law.<br/><br/></p>
            <p>(b) To implement computer, physical and procedural safeguards to protect the security and confidentiality of the personal data we collect.<br/><br/></p>
            <p>(c) To limit the personal data collected to the minimum required to provide a better service.<br/><br/></p>
            <p>(d) To permit only properly trained, authorised employees to access personal data.<br/><br/></p>
            <p>(e) To not disclose your personal data to external parties unless you have agreed, we are required by law or we have previously informed you.<br/><br/></p>
            <p>Please read this Privacy Notice carefully and ensure that you understand it. Your acceptance of this Privacy Notice is requested when you visit Our Site, use our services, or receive our email communications.<br/><br/></p>

                <h2>1. Information about us</h2>
                <p><br/> MySnapshot Limited (the “Data Controller”), trading as MySnapshot, is a limited company registered in England with company number 12993819. Our registered office is at MySnapshot Limited, 3rd Floor, 86-90 Paul Street, London, EC2A 4NE, United Kingdom. Our registration number with the Information Commissioner’s Office is ZA855864.<br/><br/></p>
    
                <h2>2. Information we collect about you</h2>
                <p><br/> Our purpose for processing your personal data is to provide assessments and insights to our clients’ education businesses and provide their users with personalised reports to support their learning experience.<br/><br/></p>
                <p>In order to provide a better service to you, we may collect information when you register with us, use our services, attend our functions, or communicate with us.<br/><br/></p>
                <p>As part of our mission to facilitate MySnapshot, we collect information and maintain a database. In the process, we also collect information of your visits to Our Site and the resources that you access including, but not limited to, traffic data, location data, weblog statistics and other communication data.<br/><br/></p>
                <p>Any information that may be provided by completing any forms or using MySnapshot’s website, including but not limited to registering to receive information such as a newsletter, using the website, and when contacting us by sending a message through the website contact page. Any assessment information under your authorisation, that may be needed to deliver our service, including the option of having your information obtained from your data provider. This can include: your name, email address, course name, cohort number, assessment responses, and net promoter score responses.<br/><br/></p>
                <p>If specific information is requested but not supplied, we may be unable to provide the requested service.<br/><br/></p>
                <p>We use an email delivery system to send emails related to your use of our system and services. Trackers are used in those emails to help us understand which emails you read and find useful.<br/><br/></p>
                <p>We only retain personal data for so long as it is necessary for you to complete your assessment and we will delete it six years after you finish using our platform.<br/><br/></p>
                <p>MySnapshot may also collect information provided when you communicate with us.<br/><br/></p>

                <h2>3. Use of your information</h2>
                <p><br/>We use information for a few different purposes and these each have a different lawful basis.<br/><br/></p>
                <p>If you are an existing MySnapshot customer, we hold your name and contact details because we have a legitimate business interest in delivering our assessment services to you. We need your contact details to deliver our services (such as sending you update emails when you need to take actions, sending you invoices) We will hold your information for six years from the expiry of your MySnapshot contract or subscription, for legal records.<br/><br/></p>
                <p>If you are a previous MySnapshot customer, in the six year period following the end of your contract or subscription, we may continue to contact you with information about MySnapshot’s services because we think you will find it useful. We believe we have a legitimate interest in this direct marketing activity (and this is also permitted under the Privacy and Electronic Communications Regulations or “PECR”) but you are always able to unsubscribe by clicking the link at the bottom of the emails we send.<br/><br/></p>
                <p>If you are a follower or a customer of a MySnapshot customer, we hold your name and email address so that we can provide our services to the educational business that you use. It is in our legitimate interests to process your personal data in order to give you a better learning experience. We do not ask for or use more data than necessary to provide you with a proper service. This means we only process and hold data related to purchases you make through your educational provider. We will hold your personal information for six years from the expiry of your educational provider’s contract or subscription, for legal records.<br/><br/></p>
                <p>If you are a supplier or other business associated with MySnapshot's field of work, we will hold your contact details because we have a legitimate interest in doing business with your company. We will aim to hold this information for six years since we were last in contact with you.<br/><br/></p>
                <p>If you’re not an existing customer of MySnapshot, we may have your contact details on our marketing list so we can call you or send you emails and newsletters about our service, along with information we think you’ll find interesting and useful. We do this because we have a legitimate interest in promoting our service and helping others understand how to provide useful self-assessments and learning experiences. You may ask us to stop contacting you at any time by selecting the “unsubscribe” option at the bottom of email information we send to you. If you remain on our marketing list, we will hold your information for two years from the time we last checked that you wanted to receive communication from us.<br/><br/></p>
                <p>MySnapshot does not reveal any identifiable information about individuals to any advertisers, but it may be required that on occasion, we provide advertisers with aggregate statistical information about our website visitors.<br/><br/></p>
                <p>MySnapshot will not disclose any information obtained from a data provider under your consent to any third parties.<br/><br/></p>

                <h2>4. Disclosure</h2>
                <p><br/>We will keep the personal data we hold confidential but may provide information to:<br/><br/></p>
                <ul className="list-disc list-inside pl-6">
                <li>personnel, agents, advisers, auditors, contractors, financial institutions, and service providers in connection with our operations or services, and only if this should be necessary</li>
                <li>our overseas offices, affiliates, business partners and counterparts</li>
                <li>persons under a duty of confidentiality to us</li>
                <li>persons to whom we are required to make disclosure under applicable laws in and outside the United Kingdom</li>
                <li>actual or proposed transferees or participants of our services</li>
                <li>where we sell any or all of our business and/or our assets to a third party</li>
                <li>where we are legally required to disclose your information</li>
                <li>assist fraud protection and minimise credit risk</li>
                </ul>
                <p>Whilst MySnapshot takes every measure to keep your personal data secure and ensures this through all our data processors, MySnapshot cannot control how third parties such as law enforcement agencies or government organisations use information, and therefore cannot be held liable for their actions.<br/><br/></p>

                <h2>5. Cookies</h2>
                <p><br/>We do not collect any personal data through cookies.<br/><br/></p>
                
                <h2>6. Security</h2>
                <p><br/>MySnapshot is committed to ensuring that your information is secure and has put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.<br/><br/></p>
                <p>All computer systems are placed in restricted areas, with access controlled buildings, and passcode protected devices/computers where only authorised employees can access personal data. These employees are trained on our privacy policies.<br/><br/></p>
                <p>MySnapshot will take all reasonable measures to make sure that all data is treated securely and in agreement with this privacy notice.<br/><br/></p>

                <h2>7. Hyperlinks</h2>
                <p><br/>Hyperlinks to other websites are provided for your convenience. You may leave our website for another location that is not controlled by us.<br/><br/></p>
                <p>Our privacy notice does not apply to other websites. Please refer to the privacy notices of the other websites. <br/><br/></p>
                <p>We do not accept any responsibility or liability for their notices whatsoever as we have no control over them.<br/><br/></p>

                <h2>8. Changes to this Privacy Notice</h2>
                <p><br/>We may change this Privacy Notice from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection.<br/><br/></p>
                <p>Any changes will be immediately posted on Our Site and you will be deemed to have accepted the terms of the Privacy Notice on your first use of Our Site following the alterations. We recommend that you check this page regularly to keep up-to-date.<br/><br/></p>
    
                <h2>9.  Data processor and transfer of personal information outside the EU</h2>
                <p><br/>We use external companies for maintaining the technical operation of our website and services. These companies are data processors for the personal data for which we are the data controller.<br/><br/></p>
                <p>The data processors must act solely in accordance with instructions from us.<br/><br/></p>
                <p>The data processors have taken reasonable technical and organisational measures to protect against the information being accidentally or illegally destroyed, lost or deteriorated, and to protect against the information being disclosed to unauthorised persons, being misused, or in other ways being processed. On your request we must supply you with sufficient information to demonstrate that the above-mentioned technical and organisational safety measures have been taken.<br/><br/></p>
                <p>Some of these data processors and third party services are located outside of the European Union. Where we transfer personal data outside of the EU and UK, we either transfer personal data to countries that provide an adequate level of protection (as determined by the European Commission) or we have appropriate safeguards in place.<br/><br/></p>
                <p>Appropriate safeguards to cover these transfers are in the form of standard contractual/data protection clauses adopted by the European Commission.<br/><br/></p>

                <h2>10.  Your rights</h2>
                <p><br/>Under the Data Protection Legislation, you have the following rights, which we will always work to uphold:<br/><br/></p>
                <p>(a) To be informed about our collection and use of your personal data. This Privacy Notice should tell you everything you need to know, but you can always contact us to find out more or to ask any questions at hello@mysnapshot.co.<br/><br/></p>
                <p>(b) To check whether we hold personal data about you and to access such data (within 30 days of your request).<br/><br/></p>
                <p>(c) To require us to correct inaccurate data or erase data (in some cases we may not be able to comply with such requests for example where we have made financial transactions with our clients’ education businesses related to your use of our site).<br/><br/></p>
                <p>(d) To object to our processing of your personal data - in this case we must stop processing it until we have determined whether your rights to privacy override our legitimate interests in processing your data.<br/><br/></p>
                <p>(e) To ascertain our policies and practices in relation to personal data and the kind of personal data held by us.<br/><br/></p>
                <p>At all times, you have the right to report a concern or lodge a complaint with the Information Commissioner’s Office. Please refer to the ICO at https://ico.org.uk/concerns  or by calling them on 0303 123 1113.<br/><br/></p>
                <p>We would welcome the opportunity to resolve your concerns ourselves, however, so please contact us first, at: hello@mysnapshot.co.<br/><br/></p>
    
                <p><br/>Version 3.0, 13 May 2024</p>
            </div>

        </section>
        </>
       
    )

} 

export default PrivacyPolicyPage
